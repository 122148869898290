header {
    background-color: $secondary-color;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: fit-content;
    padding: 10px;

    max-width: 1200px;
    margin: 0 auto;

    &__left {
        display: flex;
        align-items: center;
        margin-right: 16px;

        &--image {
            color: $primary-color;
            height: 100px;
            width: 175px;
            margin-left: 10px;
        }
    }

    &__search {
        width: 100%;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 10px;
        border: none;
        height: 18px;
        padding: 5px;
    }

    &__right {
        display: flex;
        margin-right: 16px;

        &--button {
            margin-left: 8px;
        }
    }
}
