.admin-portal {
    display: flex;
    justify-content: space-between;
    padding: 30px;

    &__left {
        display: grid;
        width: 100%;
        row-gap: 10px;
    }

    .admin-sidebar {
        display: grid;
        row-gap: 8px;
        margin-left: 30px;

        &--community {
            display: flex;
            justify-content: space-between;
        }

        &__add {
            display: flex;
            justify-content: space-between;
            Button {
                margin-left: 8px;
            }
        }
    }
}

.admin-portal-widgets {
    margin-top: 40px;
    display: flex;
    width: 1200px;
    overflow-x: scroll;
    justify-content: space-between;
}

.admin-portal-widgets-row-2 {
    margin-top: 40px;
    display: flex;
    width: 1200px;
    overflow-x: scroll;
    justify-content: left;
}

.admin-portal-widgets-row-3 {
    margin-top: 40px;
    display: flex;
    width: 1200px;
    overflow-x: scroll;
    justify-content: space-between;
}
