.session-form__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #93ada3;

    .form {
        display: grid;
        margin-top: 8px;
        row-gap: 8px;
        padding: 20px;
        background-color: #1a3947;
        width: 550px;
        text-align: center;
        border-radius: 20px;
        align-items: center;
        grid-gap: 10px;

        &__image {
            margin-top: 8px;
            justify-self: center;
            color: white;
            width: 100px;
            height: 175px;
        }

        &__field {
            border-radius: 10px;
            border: none;
            font-size: 20px;
            height: 30px;
            text-align: center;
            padding: 10px;
        }

        label {
            color: $white;
        }

        &--errors {
            color: $primary-color;
        }
    }

    .form--singup {
        grid-template-columns: 1fr 1fr;
        width: 700px;

        .form__image {
            grid-column: span 2;
        }

        .form__bottom {
            display: grid;
            grid-gap: 20px;
            grid-column: span 2;
            max-width: 500px;
            place-self: center;
        }
    }
}
