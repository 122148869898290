.manage-comments-card {
    width: 500px;
    border: 2px solid gray;
    border-radius: 15px;
    padding: 20px;
    font-size: 12px;
    min-height: 600px;
    margin-right: 20px;
}

.manage-comments-card h2 {
    font-size: 16px;
}

.search-comments-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.search-comments-form input {
    width: 250px;
    margin-bottom: 10px;
}

.search-comments-form label {
    white-space: nowrap;
    margin-right: 10px;
}

.comment-tile {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    background-color: #e5e5e5;
    padding: 10px;
    border-radius: 10px;
}

.comment-delete-btn,
.comment-approve-btn,
.comments-see-more button {
    background-color: transparent;
    border: none;
    color: gray;
}

.comments-see-more {
    margin: 15px 0;
}

.comment-user-info {
    display: flex;
    justify-content: space-between;
}

.comment-username {
    display: flex;
}

.comment-user-info p {
    margin: 0 10px 0 0;
}

.comment-content {
    margin: 10px 0;
}

.comment-btns {
    display: flex;
    width: 50%;
    justify-content: space-between;
}
