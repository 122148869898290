$line-height-base: 1.4;
$input-height-base: 20px;

// r-select
.r-select {
    max-width: 100%;

    &--is-default {
        .r-select__control {
            opacity: 0.5;
        }
    }

    &--no-indicator {
        .r-select__control {
            opacity: 0.5;
        }
    }

    .r-select {
        &__control {
            // background-color: hsl(0,0%,100%);
            // border-color: hsl(0,0%,80%);
            // border-style: solid;
            // border-width: 1px;
            // min-height: 38px;
            align-items: center;
            border-radius: 4px;
            cursor: default;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            outline: 0 !important;
            position: relative;
            transition: all 100ms;
            box-sizing: border-box;
            // ^ original r-select css

            min-height: $input-height-base;
            margin: 0px;
            border-width: 1px;
            border-style: solid;
            background-color: transparent;
            line-height: $line-height-base;
            box-shadow: none;
        }

        // value container

        &__value-container {
            align-items: center;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            padding: 3px 0px 3px 8px;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            // ^ original r-select css

            // min-height: $input-height-base;
            height: fit-content;

            &--is-multi.r-select__value-container--has-value {
                padding: 3px 0 3px 4px;
            }

            input[type='text'] {
                height: auto;
            }
        }

        //values within value container

        &__placeholder {
            color: hsl(0, 0%, 50%);
            //margin-left: 2px;
            // margin-right: 2px;
            // position: absolute;
            // top: 50%;
            // transform: translateY(-50%);
            box-sizing: border-box;
            // ^ original r-select css

            margin: 0;
            min-width: 150px;
            order: 2;
            position: relative;
            top: none;
            transform: none;
        }

        // below div not affected by classname/prefix prop
        // may cause issue in the future.
        &__value-container > div:last-child,
        &__input {
            // margin: 2px;
            // padding-bottom: 2px;
            // padding-top: 2px;
            visibility: visible;
            // color: hsl(0, 0%, 20%);
            box-sizing: border-box;
            // ^ original r-select css

            padding: 0px;
            margin: 0px;
            line-height: $line-height-base;
            order: 1;
            color: $gray;
        }

        &__dummy-input {
            background-color: transparent;
            width: 1px;
            overflow: hidden;

            box-sizing: content-box;
            border: 0px;
            font-size: inherit;
            opacity: 1;
            outline: 0px;
            padding: 0px;
            color: transparent;
            caret-color: $gray;
        }

        &__single-value {
            // color: hsl(0, 0%, 20%);
            // margin-left: 2px;
            // margin-right: 2px;
            // max-width: calc(100% - 8px);
            overflow: hidden;
            // position: absolute;
            text-overflow: ellipsis;
            white-space: nowrap;
            // top: 50%;
            // transform: translateY(-50%);
            box-sizing: border-box;
            // ^ original r-select css

            max-width: fit-content;
            margin: 0;
            color: $gray;
            order: 2;
            position: relative;
            top: 0;
            transform: none;
            display: flex;

            a {
                color: inherit;
                display: flex;
            }
        }

        // for multi-select
        &__multi-value {
            background-color: transparentize($secondary-color, 0.85);
            border-radius: 2px;
            display: flex;
            margin: 1px 0px;
            margin-right: 4px;
            min-width: 0;
            box-sizing: border-box;
            // ^ original r-select css

            &__label {
                font-size: 85%;
                padding: 1px 3px;
                padding-left: 6px;
                overflow: hidden;
                border-radius: 2px;
                // color: hsl(0, 0%, 20%);
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
                // ^ original r-select css

                line-height: 1.3;
                color: $text-color;
            }

            &__remove {
                color: gray;

                &:hover {
                    background-color: transparentize($secondary-color, 0.25);
                    color: white;
                }
            }
        }

        // indicators
        &__indicators {
            align-items: center;
            align-self: stretch;
            display: flex;
            r-shrink: 0;
            box-sizing: border-box;
            opacity: 0.5;
        }

        &__indicator {
            padding: 0 4px;
        }

        &__indicator-separator {
            display: none;
        }

        &__dropdown-indicator {
            display: flex;
        }
    }
}

//focused state
.r-select {
    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    .r-select__control {
        outline: none !important;
        &:hover,
        &:focus,
        &:active,
        &--is-focused {
            border-width: 1px;
            outline: none;
            border-color: $primary-color;

            .r-select__indicators {
                opacity: 1;
            }
        }
    }

    .r-select__value-container {
        &:hover,
        &:focus,
        &:active {
            outline: none;
        }
    }
}

// dropDownmenu

.r-select {
    .r-select {
        &__menu {
            margin: 4px 0;
            box-shadow: 2px 2px 20px hsla(0, 0%, 0%, 0.1);
            border-radius: 4px;
            width: fit-content;
            min-width: 100%;

            &-list {
                padding: 4px 0;
                max-height: 90vh;
                overflow-x: auto;
            }

            &-notice--no-options {
                padding: 0 12px;
            }
        }

        //for grouped data.

        &__group {
            padding-bottom: 8px;
            padding-top: 8px;
            box-sizing: border-box;
            // ^ original r-select css

            &-heading {
                cursor: default;
                display: block;
                font-size: 75%;
                font-weight: 500;
                margin-bottom: 0.25em;
                // padding-left: 12px;
                // padding-right: 12px;
                text-transform: uppercase;
                box-sizing: border-box;
                // ^ original r-select css

                padding: 0 8px;
            }
        }

        // individual row of item

        &__option {
            //   padding: $padding-base-vertical $padding-base-horizontal;
            white-space: nowrap;
            color: $text-color;
            display: flex;
            align-items: center;

            a {
                color: inherit;
                flex: 1;
                //hack to enlarge click area
            }

            &--is-focused,
            &:focus {
                background-color: transparentize($primary-color, 0.65);
                outline: none;
                color: $text-color;
            }

            &--is-disabled {
                opacity: 0.3;
            }

            &--is-selected {
                background-color: transparentize($primary-color, 0.65);
                color: $white;
            }

            &-toggle--close {
                transform: rotate(-90deg);
                transition: transform 0.1s;
            }

            &--heading {
                color: transparentize($gray, 0.65);
            }
        }
    }
}

.r-select {
    .r-select__single-value,
    .r-select__option {
        img {
            max-height: 24px;
            place-self: center;
        }
    }
}

//r-select + style
.r-select {
    //r-select size
    &--xs {
        .r-select__control {
            border-radius: 3px;
            min-height: 0;
        }

        .r-select__value-container {
            padding: 0 4px 0 2px;

            & > div:last-child,
            .r-select__input {
                line-height: 1;
            }
        }

        .r-select__single-value,
        .r-select__placeholder,
        .r-select__control .css-w8afj7-Input,
        .r-select__input {
            line-height: 1;
            font-size: 11px;
            font-weight: 500;
        }

        .r-select__multi-value__label {
            font-size: 0.9em;
            word-break: break-all;
            padding: 2px 0 2px 4px;
            line-height: 1;
        }

        .r-select__indicators {
            display: none;
        }
    }

    &--sm {
        .r-select__control {
            border-radius: 3px;
            min-height: 0;
        }

        .r-select__value-container {
            padding: 1px 5px;

            & > div:last-child,
            .r-select__input {
                line-height: 1.3;
            }
        }

        .r-select__single-value,
        .r-select__placeholder,
        .r-select__control .css-w8afj7-Input,
        .r-select__input {
            line-height: 1.3;
            font-size: 13px;
            font-weight: 500;
        }

        .r-select__multi-value__label {
            font-size: 0.9em;
            word-break: break-all;
            padding: 2px 0 2px 4px;
            line-height: 1;
        }

        .r-select__indicators {
            display: none;
        }
    }
}
