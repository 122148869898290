.my-journal {
    display: flex;

    &__communities {
        display: grid;
        width: fit-content;
        height: fit-content;
        row-gap: 8px;
        border: 1px solid $primary-color;
        margin-right: 8px;
        padding: 8px;

        &--header {
            margin: 0;
        }

        &--label {
            color: $secondary-color;

            &:hover {
                color: $primary-color;
                transition: 0.3s ease-in-out;
                cursor: pointer;
            }
        }
    }
}
