.supportportal {
    display: grid;
    h1 {
        margin-left: 10px;
        margin-top: 10px;
    }

    &__bottom {
        display: flex;

        .faqs-container {
            display: grid;
            flex: 3;

            .faq {
                display: grid;
                row-gap: 8px;
                border: 1px solid $primary-color;
                padding: 15px;
                border-radius: 20px;
                margin-top: 10px;

                &__bottom {
                    display: flex;
                    justify-content: left;
                }
            }
        }

        .videos-container {
            display: grid;
            flex: 1;
            margin-left: 8px;
            margin-top: 10px;
            justify-content: center;
        }
    }
}
