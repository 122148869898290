.manage-users-card {
    width: 320px;
    border: 2px solid gray;
    border-radius: 15px;
    padding: 20px;
    font-size: 12px;
    min-height: 600px;
}

.manage-users-card h2 {
    font-size: 16px;
}

.user-tile {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.user-delete-btn,
.users-see-more button {
    background-color: transparent;
    border: none;
    color: gray;
}

.users-see-more {
    margin: 15px 0;
}

.user-info {
    display: flex;
    justify-content: space-between;
    width: 280px;
}

.user-info p {
    margin: 0;
}

.user-edit {
    margin: 10px 0 5px;
}
