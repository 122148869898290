.manage-communities-card {
    width: 300px;
    border: 2px solid gray;
    border-radius: 15px;
    padding: 20px;
    font-size: 12px;
    min-height: 600px;
    margin-right: 20px;
}

.manage-communities-card h2 {
    font-size: 16px;
}

.add-community-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 250px;
}

.add-community-form button {
    white-space: nowrap;
    font-size: 12px;
    padding: 5px 10px;
}

.add-community-form input {
    width: 130px;
}

.community-tile {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.community-tile a {
    color: black;
}

.community-delete-btn,
.communities-see-more button {
    background-color: transparent;
    border: none;
    color: gray;
}

.communities-see-more {
    margin: 15px 0;
}

.add-community-error {
    color: red;
    text-align: center;
}
