footer {
    // background-color: black;
    color: black;
    margin-top: 40px;
    padding: 20px;
}

.footer__container {
    max-width: 1200px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    row-gap: 20px;
}

.footer-section {
    display: grid;
    align-content: flex-start;
    grid-column: span 1;

    &__title {
        // font-weight: 500;
        color: black;
    }

    &__item,
    a.footer-section__item {
        line-height: 1.6;
        margin: 0;
        opacity: 0.5;
        color: black;
    }

    .footer-section__item a {
        color: black;
    }
}
