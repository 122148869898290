.addcommentbutton {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.createcommentform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40vw;
    height: 35vh;

    .create-comment-form {
        background-color: $white;
        display: grid;
        grid-template-rows: max-content auto max-content;
        border: 1px solid $primary-color;
        border-radius: 20px;
        padding: 10px;
        width: 100%;
        height: 100%;

        &__top {
            display: flex;
            justify-content: space-between;
            align-content: center;

            h2 {
                font-family: $primary-font;
                color: $primary-color;
                padding: 8px;
            }

            .private-toggle {
                display: flex;
                justify-self: end;
                margin-right: 8px;
                margin-top: 8px;
                max-height: max-content;
                color: $primary-color;
            }
        }

        &__body {
            display: grid;
            border: none;
            padding: 15px;
            font-size: 12pt;

            &:active,
            &:visited,
            &:focus {
                outline: none;
            }
        }

        .button {
            justify-self: end;
            padding: 12px;
            background-color: $primary-color;
            color: $white;
        }
    }
    &--errors {
        justify-self: center;
        background-color: $alert;
        margin-top: 10px;
        padding: 20px;
        border-radius: 20px;
    }
}
