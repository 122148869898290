.icon {
    fill: currentColor;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
}

.button,
button,
a.button {
    display: inline-block;
    border-radius: 10px;
    font-family: inherit;
    // width: auto;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;

    touch-action: manipulation;
    background-image: none;

    border-style: solid;
    border-width: 1px;

    &:focus,
    &:hover {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

@mixin button--solid($class, $color) {
    .button--solid--#{$class} {
        border-color: transparent;

        background-color: $color;
        color: white;

        &:hover {
            background-color: $color;
        }

        &:active {
            background-color: $color;
            color: white;
        }
    }
}

@mixin button--outline($class, $color) {
    .button--outline--#{$class} {
        border-color: $color;
        color: $color;
        background-color: transparent;

        &:hover {
            background-color: #8080801a;
        }

        &:active {
            background-color: transparent;
        }
    }
}

@mixin button--link($class, $color) {
    .button--link--#{$class} {
        border-color: transparent;
        background-color: transparent;
        color: $color;

        &:hover {
            // text-decoration: underline;
            color: $color;
        }

        &:active {
            color: $color;
        }
    }
}

//sizes
.button--sm {
    padding: 2px 5px;

    font-size: 14px;
}

.button--md {
    padding: 3px 8px;
    font-size: 16px;
}

.button--lg {
    padding: 10px 12px;
    font-size: 18px;
}

//group
.button-group {
    .button:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .button:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .button:not(:first-child) {
        margin-left: -1px;
    }

    display: flex;
    .button--block {
        flex: 1;
    }
}

@include button--solid(primary, $primary);
@include button--solid(default, $default);
@include button--solid(secondary, $secondary);
@include button--solid(white, $white);

@include button--outline(primary, $primary);
@include button--outline(default, $default);
@include button--outline(secondary, $secondary);
@include button--outline(white, $white);

@include button--link(primary, $primary);
@include button--link(default, $default);
@include button--link(secondary, $secondary);
@include button--link(white, $white);

// utility
.button--block {
    width: 100%;
}
.button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
        display: flex;
        justify-self: flex-start;
        align-items: center;
        > * {
            margin-right: 3px;
        }
    }
    &--right {
        display: flex;
        justify-self: flex-end;
        align-items: center;
        white-space: nowrap;

        > * {
            margin-left: 3px;
        }
    }
}
