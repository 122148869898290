.fourohfour {
    display: flex;
    justify-content: center;
    border: 2px solid $primary-color;
    height: 78vh;
    border-radius: 20px;
    margin: 20px;

    &--message {
        font-size: 50px;
        color: $primary-color;
        align-self: center;
    }
}
