.grid {
    display: grid;
    padding: 4px;
    row-gap: 6px;

    &-relation {
        display: flex;
        justify-content: space-between;

        &__name {
            margin-right: 10px;
        }
    }
}
