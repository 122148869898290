.forum-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 8px;

    .forum {
        display: grid;
        width: 100%;

        .button--newpost {
            border: 1px solid $primary-color;
            border-radius: 15px;
            max-width: 916px;
            text-align: left;
            padding: 8px 0px 8px 24px;
            color: grey;
            max-height: 20px;
            margin-bottom: 8px;
        }
    }
}

.no-posts-text {
    text-align: center;
    color: #1a3947;
    font-size: 1.2rem;
    opacity: 0.5;
    padding-top: 20px;
}
