body {
    margin: 0;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.layout {
    flex: 1;

    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    padding: 20px 20px 0;
}

.layout__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.layout__sidebar {
    min-width: 200px;
    margin-right: 8px;
    display: 'grid';
    .button {
        display: block; // prevent inline
    }
}

.sidebar__header {
    a {
        color: inherit;
    }
}
