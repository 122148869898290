.tile-grid {
    display: grid;
    grid-gap: 24px;
    margin-top: 20px;
}

.tile {
    border-radius: 10px;
    padding: 10px;

    // height: 150px;

    display: flex;
    flex-direction: column;

    &__body {
        flex: 1;
    }

    &__footer {
        padding-top: 10px;
    }

    &--green {
        background: #93ada3;
        color: white;
    }

    &--orange {
        background: #dc7646;
        color: white;
    }

    &--amber {
        background: transparentize($color: $primary-color, $amount: 0.65);
    }

    &--yellow {
        background: transparentize($color: $primary-color, $amount: 0.85);
        color: black;
    }

    &--outline {
        background-color: transparent;
        border: 3px solid transparentize($color: $primary-color, $amount: 0.85);
    }
}
