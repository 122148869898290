@import './variables.scss';

@import './navbar.scss';
@import './fourohfour.scss';
@import './forum.scss';
@import './post.scss';
@import './session_form.scss';
@import './buttons.scss';
@import './my_journal.scss';
@import './footer.scss';
@import './admin_portal.scss';
@import './support_portal.scss';
@import './layout.scss';
@import './tile.scss';
@import './r-select.scss';
@import './modal.scss';
@import './comment.scss';
@import './tooltips.scss';
@import './pill.scss';

* {
    font-family: $primary-font;
    font-weight: 300;
}

#root .app {
    min-width: 100vw;
    min-height: 100vh;
}

a,
a:hover,
a:visited,
a:active,
a:focus {
    text-decoration: none !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    margin: 0;
    color: $secondary-color;
    font-weight: 400;
    font-size: 22px;
}

input {
    &:active,
    &:focus {
        outline: none;
    }
}

.toggle {
    appearance: none;
    width: 32px;
    height: 16px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #707070;
    transition: background-color ease 0.3s;

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        width: 12px;
        height: 12px;
        background: #fff;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        font: 10px/28px Helvetica;
        text-transform: uppercase;
        font-weight: bold;
        text-indent: -22px;
        word-spacing: 37px;
        color: #fff;
        text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
        white-space: nowrap;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
    }

    &:checked {
        background-color: #e7a54190;
    }
    &:checked:before {
        left: 18px;
    }
}

.linear-loader {
    overflow: hidden;
    width: 50%;
    height: 4px;
    background-color: transparentize($color: $primary-color, $amount: 0.65);
    margin: 20px auto;
}

.linear-loader__bar {
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        background-color: $primary-color;
        animation: linear-load 1.5s infinite ease-out;
    }
}

@keyframes linear-load {
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}

//utility classes

.text-mute {
    opacity: 0.5;
}
