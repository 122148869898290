.post {
    display: grid;
    row-gap: 8px;
    padding: 20px;
    background: transparentize($color: $primary-color, $amount: 0.85);
    border-radius: 20px;
    max-width: 900px;
    color: $secondary-color;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        a {
            color: inherit;

            span {
                padding-left: 4px;
            }
        }

        .private-toggle {
            display: flex;
            align-items: center;
            color: $primary-color;
        }
    }

    &__buttons {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--left {
            display: flex;
            flex: auto;
        }

        &--right {
            .private-flag {
                color: black, 30%;
            }
        }
    }

    .button {
        margin-right: 3px;
        z-index: 100;
    }
}

.private-true {
    background: transparentize($color: $primary-color, $amount: 0.65);
}

.post__date {
    opacity: 0.6;
    font-weight: 0.9em;
}

.post__content {
    color: black;
}

.post__name {
    font-weight: 500;
    margin-right: 6px;
}

.post__pronouns {
    margin-right: 6px;
    opacity: 0.6;
    font-weight: 300;
}

.post__form {
    textarea {
        width: 100%;
        outline: none;
        border: 0;
        font-size: inherit;
    }
}

.post__footer {
    display: flex;
    justify-content: space-between;
}

.comment {
    display: grid;
    background: transparentize($color: $primary-color, $amount: 0.85);
    border-radius: 20px;
    padding: 20px;
    row-gap: 8px;
    margin-bottom: 20px;
    margin-left: 10%;
    color: $secondary-color;

    &__top {
        display: flex;
        justify-content: space-between;
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--username {
            color: $primary-color;
        }
    }
}

.new-post {
    transition: max-height 0.5s;
    overflow-x: auto;

    &--show {
        max-height: 10000px;
    }
    &--hide {
        max-height: 24px;
    }
}

.createpostform {
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 76vh;

    .create-post-form {
        display: grid;
        grid-template-rows: max-content auto max-content;
        border: 1px solid $primary-color;
        border-radius: 20px;
        width: 850px;
        height: 350px;
        padding: 10px;

        &__top {
            display: flex;
            justify-content: space-between;
            align-content: center;

            h2 {
                font-family: $primary-font;
                color: $primary-color;
                padding: 8px;
            }

            .private-toggle {
                display: flex;
                justify-self: end;
                margin-right: 8px;
                margin-top: 8px;
                max-height: max-content;
                color: $primary-color;
            }
        }

        &__body {
            display: grid;
            border: none;
            padding: 20px;

            &:active,
            &:visited,
            &:focus {
                outline: none;
            }
        }

        .button {
            justify-self: end;
            padding: 12px;
        }
    }
    &--errors {
        justify-self: center;
    }
}

.private-toggle {
    margin-bottom: 0;
}

.tile__header {
    font-weight: bold;
}

.tile__body {
    padding-left: 3px;
}

.private-toggle-pad {
    padding-right: 5px;
}

.post__footer {
    margin-bottom: 5px;
}
