@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary-color: #e7a541;

$secondary-color: #1a3947;

$primary-font: 'Fira Sans', helvetica;

$primary: #e7a541;
$secondary: #1a3947;
$blue: blue;
$green: green;
$default: default;
$white: white;
$gray: gray;
$alert: #e74c3c;
$text-color: #2b2b2b;
